import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import ButtonOutlined from "../../../global/atoms/ButtonOutlined";
import {
  IPhotoReviewModal,
  IPhotoReviewErrors,
  IReviewPhotoSimple,
} from "../../../utils/models";
import styles from "./styles.module.scss";
import stylesCommon from "../../../global/common.module.scss";
import MyReviewForm from "./Review/MyReviewForm";
import SupervisorReviewForms from "./Review/SupervisorReviewForms";
import ButtonSubmit from "../../../global/atoms/ButtonSubmit";
import { default as IconButtonLoc } from "../../../global/atoms/IconButton";
import Connection, {
  IPhotoReviewFormsSingular,
} from "../../../utils/connections/visitReviews";
import { srcPlaceholder } from "./PhotoReviewThumb";
import axios from "axios";
import SpinnerSmall from "../../../global/atoms/Spinner/SpinnerSmall";
import { Button, Carousel, Col, IconButton, Tag } from "rsuite";
import { Helmet } from "react-helmet-async";
import { IRoot } from "redux/models";
import { useSelector } from "react-redux";
import SeparatorLine from "global/atoms/separators/SeparatorLine";
import ShareOutlineIcon from "@rsuite/icons/ShareOutline";
import ReloadIcon from "@rsuite/icons/Reload";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import ImageIcon from "@rsuite/icons/Image";
import InstancesConnection from "../../../utils/connections/instances";
import { deepClone, rotateBase64Img } from "utils/helpers";
import UniversalModal from "global/atoms/UniversalModal";
import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";
import VisitCommentsList from "./Review/VisitCommentsList";
import CheckIcon from "@rsuite/icons/Check";
import Instances from "../../../utils/connections/instances";

interface IPhotoReviewModalComponent {
  submitReview(openNext?: boolean): void;

  goToPhoto?(newIdentifier: string): void;

  onClose(): void;

  modalData: IPhotoReviewModal | null;
  errors: IPhotoReviewErrors;
  readOnly?: boolean;
  noNav?: boolean;
}

const PhotoReviewModal: FunctionComponent<IPhotoReviewModalComponent> = (
  props
) => {
  const [navPrevDisabled, navPrevDisabledSet] = useState(false);
  const [navNextDisabled, navNextDisabledSet] = useState(false);
  const [rotation, setRotation] = useState(0);

  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);

  const [photosSrc, setPhotosSrc] = useState<Array<{
    id: string;
    value: string;
    comment: string;
  }> | null>([]);
  const [forms, setForms] = useState<
    IPhotoReviewFormsSingular | null | boolean
  >(null);
  const refAnswerIds =
    props.modalData?.photoSimple?.map((ph) => ph.value.refQuestionAnswerId) ??
    [];
  const [otherReviewersToggled, setOtherReviewersToggled] = useState(false);

  const photoReviewState = useSelector((state: IRoot) => state.photoReview);
  const handleCloseModal = () => props.onClose();

  const CopyToClipboardButton = () => {
    const clipboardRef = useRef(null);
    const handleCopyClick = () => {
      if (clipboardRef.current) {
        const element: HTMLElement = clipboardRef.current;
        const textToCopy: string | null = element.getAttribute(
          "data-clipboard-text"
        );
        if (textToCopy)
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              ToastNotificationPush(
                ToastTypes.success,
                "Skopiowano link do schowka!"
              );
            })
            .catch(() => {
              ToastNotificationPush(
                ToastTypes.error,
                "Błąd obsługi schowka lub schowek niedostępny!"
              );
            });
      }
    };

    return (
      <IconButton
        ref={clipboardRef}
        icon={<ShareOutlineIcon color="rgba(255, 175, 56, 0.9)" />}
        data-clipboard-text={getReviewModalUrl()}
        onClick={handleCopyClick}
        size={"lg"}
        appearance={"subtle"}
      />
    );
  };

  // mount
  useEffect(() => {
    if (props.modalData?.open) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.modalData?.open]);

  useEffect(() => {
    if (photoReviewState !== null) {
      setSaveButtonEnabled(true);
    }
  }, [photoReviewState]);

  const loadForm = () => {
    setForms(null);
    Connection.getFeedbackForms(refAnswerIds)
      .then((result) => {
        setForms(result.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) setForms(false);
      });
  };

  const load = () => {
    if (!props.modalData?.open) return;

    loadForm();
    setPhotosSrc([]);
    setOtherReviewersToggled(false);

    // load full photos
    Connection.getFullSizePhotos(refAnswerIds)
      .then((result) => {
        setPhotosSrc(
          result.data.map((o) => {
            if (o.value === "") return { ...o, value: srcPlaceholder };
            return o;
          })
        );
      })
      .catch((e) => {
        if (!axios.isCancel(e)) setPhotosSrc(null);
      });
  };

  // init - open
  useEffect(() => {
    if (props.modalData?.open) {
      load();
      navPrevDisabledSet(!props.modalData.prevId);
      navNextDisabledSet(!props.modalData.nextId);
    } else {
      // ...
    }
  }, [props.modalData?.open, props.modalData?.toggler]);

  const onNavigationChange = (type: "prev" | "next") => {
    if (!props.goToPhoto) return;
    const targetId =
      type === "prev" ? props.modalData?.prevId : props.modalData?.nextId;
    if (!targetId) return;
    navPrevDisabledSet(false);
    navNextDisabledSet(false);
    props.goToPhoto(targetId);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowLeft":
        onNavigationChange("prev");
        break;
      case "ArrowRight":
        onNavigationChange("next");
        break;
      case "Escape":
        handleCloseModal();
        break;
    }
  };

  const getAnswerIdsFromModalData = (): Array<string> => {
    return (
      props.modalData?.photoSimple?.filter(
        (ps) => ps.hasKPIFeedback || ps.hasSLAFeedback
      ) ?? []
    ).map((ps) => ps.value.refQuestionAnswerId);
  };
  const hasReviews = (): boolean => {
    return (
      (
        props.modalData?.photoSimple?.filter(
          (ps) => ps.hasKPIFeedback || ps.hasSLAFeedback
        ) ?? []
      ).length > 0
    );
    // return (photoReviewState?.refAnswerIds.length ?? 0) > 0
  };

  const navigation = () => {
    if (!props.modalData?.photoSimple || props.noNav === true) return <></>;

    return (
      <>
        <div className={`navigation`}>
          <div
            className={`arrowLeft ${styles.navigationArrow}`}
            style={{ left: "-30px" }}>
            <IconButtonLoc
              disabled={navPrevDisabled}
              size={100}
              icon={"arrowLeft"}
              onClick={onNavigationChange.bind(null, "prev")}
            />
          </div>
          <div
            className={`arrowRight ${styles.navigationArrow}`}
            style={{ right: "-30px", transform: "rotate(180deg)" }}>
            <IconButtonLoc
              disabled={navNextDisabled}
              size={100}
              onClick={onNavigationChange.bind(null, "next")}
              icon={"arrowLeft"}
            />
          </div>
        </div>
      </>
    );
  };

  const handleButtonSubmitOnClick = (openNext: boolean): void => {
    if (!photoReviewState) return;
    props.submitReview(openNext);
  };

  const getReviewModalUrl = (): string => {
    const q: { [paramName: string]: string } = {};

    if (props.modalData?.photoSimple?.length === 1) {
      q["visitId"] = props.modalData?.visit?.id ?? "";
      q["answerId"] = props.modalData?.photoSimple[0].value.refQuestionAnswerId;
    } else if ((props.modalData?.photoSimple?.length ?? 0) > 1) {
      q["visitId"] = props.modalData?.visit?.id ?? "";
      // @ts-ignore
      q["questionId"] = props.modalData?.photoSimple[0].questionId ?? "";
      // @ts-ignore
      if (props.modalData?.photoSimple[0].itemId) {
        q["itemId"] = props.modalData?.photoSimple[0].itemId;
      }
    }

    return `${window.location.protocol}//${
      window.location.host
    }/visit-reviews?${new URLSearchParams(q).toString()}`;
  };

  const getPhotoSimple = (): IReviewPhotoSimple | null => {
    const tmp = props.modalData?.photoSimple ?? null;
    return tmp && tmp.length > 0 ? tmp[0] : null;
  };

  if (!props.modalData?.open) return <></>;
  const saveRotatedPhoto = (questionAnswerId: string) => {
    ///api/public/question-answer-photo PUT
    Instances.putRotatedImage(questionAnswerId, rotation * 90).then(() => {
      setRotation(0);
    });
  };

  const getBody = () => {
    return (
      <>
        <Col xs={12} style={{ height: "100%", overflow: "hidden" }}>
          {(photosSrc?.length ?? 1) === 0 ? (
            <div
              className={stylesCommon.loadingSmall}
              style={{ marginBottom: "15px", minWidth: "45vw" }}>
              <SpinnerSmall />
              Ładowanie zdjęcia...
            </div>
          ) : photosSrc === null ? (
            <div
              className={stylesCommon.loadingSmallErr}
              style={{ marginBottom: "15px", minWidth: "45vw" }}>
              Wystąpił błąd lub utracono połączenie.
            </div>
          ) : (
            <Carousel
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                height: "100%",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              }}
              className="slider">
              {photosSrc.map((src) => (
                <div
                  key={`p-src-${src.id}`}
                  className={styles.modalPhotoContainer}
                  style={{
                    backgroundImage: `url("${src.value}")`,
                    height: "100%" /* You must set a specified height */,
                    backgroundPosition: "center" /* Center the image */,
                    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
                    backgroundSize:
                      "contain" /* Resize the background image to cover the entire container */,
                  }}>
                  <div
                    style={{
                      margin: "8px",
                      position: "relative",
                      height: "100%",
                    }}>
                    <IconButton
                      icon={<ReloadIcon color="rgba(255, 175, 56, 0.9)" />}
                      size={"lg"}
                      appearance={"subtle"}
                      onClick={() => {
                        setRotation((r) => {
                          return ++r % 4;
                        });
                        const tmp = deepClone(photosSrc);
                        tmp[
                          photosSrc.findIndex((ps) => ps.id === src.id)
                        ].value = rotateBase64Img(src.value);
                        setPhotosSrc(tmp);
                      }}
                    />
                    &nbsp;
                    <IconButton
                      disabled={rotation == 0}
                      title="Zapisz obrócone zdjęcie"
                      icon={<CheckIcon color="rgba(255, 175, 56, 0.9)" />}
                      size={"lg"}
                      appearance={"subtle"}
                      onClick={saveRotatedPhoto.bind(null, src.id)}
                    />
                    &nbsp;
                    <IconButton
                      icon={<ImageIcon color="rgba(255, 175, 56, 0.9)" />}
                      size={"lg"}
                      title={"Otwórz zdjęcie w nowej karcie"}
                      appearance={"subtle"}
                      onClick={() => {
                        const downloadPhotoUrl = Connection.downloadPhotoUrl(
                          src.id
                        );
                        window.open(downloadPhotoUrl);
                      }}
                    />
                    &nbsp;
                    <IconButton
                      icon={
                        <FileDownloadIcon color="rgba(255, 175, 56, 0.9)" />
                      }
                      size={"lg"}
                      title={"Pobierz zdjęcie"}
                      onClick={() => {
                        const imageInLineLink =
                          InstancesConnection.getQuestionImageLink(src.id);
                        window.open(imageInLineLink, "_blank");
                      }}
                      appearance={"subtle"}
                    />
                    &nbsp;
                    <CopyToClipboardButton />
                    {src.comment !== "" && (
                      <div className={styles.modalPhotoComment}>
                        {src.comment}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          )}
          {navigation()}
        </Col>
        <Col xs={12} style={{ overflow: "auto", height: "calc(100% - 75px)" }}>
          <Col xs={24} style={{ padding: "5px" }}>
            {(props.modalData?.photoSimple?.length ?? 0) > 1 &&
              `Ocena zbiorcza (${props.modalData?.photoSimple?.length} fotografie)`}
            {props.modalData?.photoSimple?.length === 1 && "Ocena zdjęcia"}
          </Col>
          <Col xs={12}>
            {props.modalData?.visit?.users.map((user, i) => (
              <Tag size={"sm"} className={`${styles.small}`} key={`u-${i}`}>
                <strong>{user.roleName}</strong>: {user.userName}
              </Tag>
            ))}
          </Col>
          <Col xs={12}>
            <Col md={12}>
              <div className={`${styles.small}`}>
                <strong>Projekt:</strong>{" "}
                {props.modalData?.visit?.projectName ?? "-"}
              </div>
              <div className={`${styles.small}`}>
                <strong>Zadanie:</strong> {getPhotoSimple()?.taskName ?? "-"}
              </div>
              <div className={`${styles.small}`}>
                <strong>Aktywność:</strong>{" "}
                {getPhotoSimple()?.activityName ?? "-"}
              </div>
              <div className={`${styles.small}`}>
                <strong>Pytanie:</strong>{" "}
                {getPhotoSimple()?.questionName ?? "-"}
              </div>
            </Col>

            <Col md={12}>
              {/* <div className={`${styles.small}`}>
                <strong>Raportujący:</strong> {props.modalData.visit?.reportingUserName ?? '-'}
              </div> */}
              <div className={`${styles.small}`}>
                <strong>Lokalizacja:</strong>{" "}
                {props.modalData?.visit?.locationName ?? "-"}
              </div>
              <div className={`${styles.small}`}>
                <strong>Data wizyty:</strong>{" "}
                {props.modalData?.visit?.startDate ?? "-"}
              </div>
            </Col>
          </Col>
          <Col xs={24}>
            {forms === null && (
              <div className={stylesCommon.loadingSmall}>
                <br />
                <SpinnerSmall />
                Ładowanie formularza oceny...
              </div>
            )}
            {forms === false && (
              <div className={stylesCommon.loadingSmallErr}>
                <br />
                Wystąpił błąd lub utracono połączenie.
              </div>
            )}
          </Col>
          <Col xs={24}>
            <div
              className={`${styles.small}`}
              style={{ marginTop: "10px", marginBottom: "10px" }}>
              <strong>Komentarze do wizyty:</strong>
              <VisitCommentsList
                visitId={props.modalData?.visit?.id ?? ""}
                projectId={props.modalData?.visit?.projectId ?? ""}
              />
            </div>
          </Col>
          <Col xs={24}>
            {forms && Object.keys(forms).length > 0 && (
              <>
                <MyReviewForm
                  reload={loadForm}
                  // @ts-ignore
                  forms={forms}
                  errors={props.errors}
                />
              </>
            )}
            {hasReviews() && (
              <>
                {!otherReviewersToggled && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <Button
                      appearance={"subtle"}
                      onClick={() => setOtherReviewersToggled(true)}>
                      Wyświetl wszystkie oceny
                    </Button>
                  </div>
                )}

                {otherReviewersToggled && (
                  <>
                    <SeparatorLine size={4} />
                    <SupervisorReviewForms
                      key={`supervisorReview_${props.modalData?.toggler}`}
                      visitQuestionAnswerIds={getAnswerIdsFromModalData()}
                    />
                  </>
                )}
              </>
            )}
          </Col>
        </Col>
        <Col xs={12}>{getFooter()}</Col>
      </>
    );
  };

  const getFooter = () => {
    return (
      <div className={styles.modalActionButtons}>
        <ButtonOutlined onClick={handleCloseModal}>Anuluj</ButtonOutlined>
        &nbsp;
        {props.readOnly !== true && forms && (
          <>
            <ButtonSubmit
              disabled={!saveButtonEnabled}
              onClick={() => {
                handleButtonSubmitOnClick(false);
              }}
            />
            {props.modalData?.nextId && (
              <>
                &nbsp;
                <ButtonSubmit
                  disabled={!saveButtonEnabled}
                  name={"Zapisz i następny"}
                  onClick={() => {
                    handleButtonSubmitOnClick(true);
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <UniversalModal
        overflow={false}
        //@ts-ignore
        size={"custom"}
        displayFooterButtons={false}
        title={""}
        body={getBody()}
        open={props.modalData?.open}
      />

      {/* <Modal
        class={"photo-review-modal"}
        key={"photo_review_modal"}
        isOpen={props.modalData?.open}
        navigation={navigation}
        inlineStyles={{ maxWidth: "62.5vw" }}
        outsideModalClick={handleCloseModal}>
        <h1 style={{ marginTop: 0 }}></h1>
        <>

        </>
      </Modal> */}

      <Helmet>
        <style>{`
        ${
          photosSrc?.length === 1
            ? `.rs-carousel-shape-dot .rs-carousel-label { width: 0; height: 0; }`
            : `.rs-carousel-shape-dot .rs-carousel-label { width: 20px; height: 20px; }`
        }
        .rs-carousel-toolbar {
          bottom: 30px !important;
        }
        .rs-modal{
          margin: 0 !important;
        }
        .rs-modal-content {
          position: fixed !important;
          top: 5px !important;
          left: 5px !important;
          right: 5px !important;
          bottom: 5px !important;
          margin: 0 auto !important;
          padding: 0 !important;
          height: 99vh !important;
          max-height: 99vh !important;
        }
        .rs-modal-body {
          padding: 1vh !important;
          position: relative !important;
          margin: 0px auto;
          height: 99vh !important;
          overflow: hidden !important;
        }
      `}</style>
      </Helmet>
    </>
  );
};

export default PhotoReviewModal;

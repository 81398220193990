import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import {
  IProjectImportAdd,
  IProjectImportFormData,
} from "../../../../utils/models";
import ButtonOutlined from "../../../../global/atoms/ButtonOutlined";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import { handleToast } from "../../../../utils/helpers";
import ProjectImports, {
  IImportFrom,
} from "../../../../utils/connections/imports";
import Select from "../../../../global/atoms/Select";
import Form from "../../../../global/form/Form";
import commonStyles from "../../../../global/common.module.scss";
import FileInput from "../../../../global/atoms/FileInput";
import { useParams } from "react-router-dom";
import { IMPORT_TIMETABLE_SHORTCUT } from "./ImportsList";
import { TypeImportType } from "../../../../utils/types";
import { Message } from "rsuite";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import UniversalModal from "global/atoms/UniversalModal";

interface IImportsModalAdd {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formData: IProjectImportFormData;
  addedCallback: () => void;
}

const ImportsModalAdd: FunctionComponent<IImportsModalAdd> = (props) => {
  const { shortcut } = useParams<{ shortcut: string }>();
  let defaultImportType: TypeImportType = "locations";

  if (shortcut === IMPORT_TIMETABLE_SHORTCUT) {
    defaultImportType = "timetable";
  }

  const formDefault: IProjectImportAdd = {
    importType: defaultImportType,
    importAction: "add",
    file: null,
  };
  const [form, setForm] = useState<IProjectImportAdd>(formDefault);
  const [errors, setErrors] = useState<any>(null);
  const [fileInputReset, setFileInputReset] = useState<number>(Date.now());
  const [importOptions, setImportOptions] = useState<null | IImportFrom>(null);

  useEffect(() => {
    if (!props.open) {
      setFileInputReset(Date.now());
      setForm(formDefault);
      setErrors(null);
    } else {
      ProjectImports.importsForm().then((res) => {
        setImportOptions(res.data);
      });
    }
  }, [props.open]);

  //check if default import type is available
  useEffect(() => {
    if (
      importOptions !== null &&
      importOptions.import_types.options.find(
        (o) => o.id == defaultImportType
      ) == undefined
    ) {
      setForm({
        ...form,
        importType: importOptions.import_types.options[0].id as TypeImportType,
      });
    }
  }, [importOptions]);

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    const formData = new FormData();
    // @ts-ignore
    formData.append("file", form.file);
    formData.append("importType", form.importType);
    formData.append("importAction", form.importAction);

    handleToast(ProjectImports.add(formData), setErrors).then((resp) => {
      if (resp.status == 200) {
        props.setOpen(false);
        props.addedCallback();
      }
    });
  };
  const importAvailable = () => {
    return (
      importOptions?.import_types.options.find(
        (o) => o.id == form.importType
      ) !== undefined
    );
  };
  return (
    <UniversalModal
      open={props.open}
      title={"Nowy import"}
      displayFooterButtons={false}
      body={
        <>
          {importOptions === null && <SpinnerSmall />}
          {importOptions !== null && (
            <>
              <Form handleSubmit={handleSubmit}>
                <div>
                  <Select
                    placeholder={"Typ importu"}
                    options={importOptions.import_types.options}
                    name={"importType"}
                    value={form.importType}
                    state={form}
                    setState={setForm}
                    errors={errors}
                  />

                  <br />

                  <Select
                    placeholder={"Akcja"}
                    options={importOptions.import_actions.options}
                    name={"importAction"}
                    value={form.importAction}
                    state={form}
                    setState={setForm}
                    errors={errors}
                  />
                </div>

                <div style={{ textAlign: "center" }}>
                  <FileInput
                    label={"Wybierz Plik*"}
                    triggerReset={fileInputReset}
                    errors={errors}
                    form={form}
                    setForm={setForm}
                    acceptType={"xlsx"}
                  />

                  {form.file === null && (
                    <div
                      style={{ marginTop: "6px" }}
                      className={commonStyles.smallMuted}>
                      *Tylko pliki z rozszerzeniem .xlsx
                    </div>
                  )}
                </div>

                {form.importType === "timetable" && (
                  <Message type={"warning"}>
                    <h1>Uwaga!</h1>
                    W przypadku kolejnych zmian w harmonogramie należy ponownie
                    pobrać plik exportowy.
                    <br />
                    Nie można 2 x zaimportować tego samego pliku po wykonanym
                    już imporcie
                    <br />
                    (przy imporcie następuje zmiana ID wpisów w harmonogramie)
                  </Message>
                )}
                {errors?.message && (
                  <Message type={"error"}>
                    <h1>Błąd operatora!</h1>
                    <b>{errors.message}</b>
                    <p>
                      plik nie został wysłany na serwer. Jeżeli jest to
                      zamierzone działanie proszę powtórzyć czynność po
                      zakończeniu poprzedniego procesu
                    </p>
                  </Message>
                )}
                <br />
                <div style={{ textAlign: "right" }}>
                  <ButtonOutlined onClick={() => props.setOpen(false)}>
                    Anuluj
                  </ButtonOutlined>
                  &nbsp;
                  <ButtonSubmit
                    disabled={form.file === null || !importAvailable()}
                    name={"Dodaj"}
                  />
                </div>
              </Form>
            </>
          )}
        </>
      }
    />
  );
};

export default ImportsModalAdd;
